<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'AdminCompany' }">
            Companies
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ activeSection }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h3" :content="myTitle"></Heading>
    <el-menu
      :default-active="activeSection"
      class="course-menu mb-3 mt-3"
      mode="horizontal"
      @select="showSection"
      background-color="#f3f3f3"
      :active-text-color="themeColor"
      :router="true"
    >
      <el-menu-item index="Info" :route="{ query: { active: 'Info' } }">
        <i class="el-icon-s-home"></i>
        {{ $t("TeacherCourses.Info") }}
      </el-menu-item>
      <el-menu-item index="Users" :route="{ query: { active: 'Users' } }">
        <i class="el-icon-user-solid"></i>
        Users
      </el-menu-item>
    </el-menu>
    <div v-show="activeSection === 'Info'">
      <el-form label-width="140px" v-if="companyInfo">
        <el-form-item label="Name" prop="title">
          <b>
            {{ companyInfo.name }}
          </b>
        </el-form-item>
        <el-form-item label="Admin" prop="title">
          <b>{{ companyInfo.admins.length }}</b>
        </el-form-item>
        <el-form-item label="Teachers" prop="title">
          <b>
            {{ companyInfo.teachers.length }}
          </b>
        </el-form-item>
        <!-- <el-form-item label="Students" prop="title">
          <b>
            {{ companyInfo.users_count - companyInfo.teachers.length }}
          </b>
          <el-tooltip effect="dark" content="Edit Students" placement="top">
            <router-link
              :to="{
                name: 'AdminCompanyUsers',
                params: {
                  id: companyId
                }
              }"
            >
              <el-button class="ml-1" type="text">
                <i class="fa fa-user-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </el-form-item> -->
      </el-form>
      <div class="row">
        <div class="col-sm-6">
          <Balance
            title="Coins for Students"
            :point="companyInfo.coin"
            :isAdmin="true"
            @editBalance="editBalance"
          />
        </div>
        <div class="col-sm-6">
          <Pro
            title="Annual Pro Accounts for Students"
            :point="companyInfo.pro_quantity"
            :isAdmin="true"
            @editPro="editPro"
          />
        </div>
      </div>
      <UserTransactions :companyId="companyId"></UserTransactions>
      <el-dialog
        title="Edit Balance"
        v-if="company"
        :visible.sync="showCompany"
      >
        <el-form label-width="100px">
          <el-form-item label="Company">
            <b>
              {{ company.name }}
            </b>
          </el-form-item>
          <el-form-item label="Balance">
            <b class="text-success">
              {{ form.oCoins }}
              <small v-if="form.oCoins > 1">Coins</small>
              <small v-else-if="form.oCoins > 0 && form.oCoins < 2">
                Coin
              </small>
            </b>
          </el-form-item>
          <el-form-item label="Type">
            <el-radio-group v-model="form.type">
              <el-radio-button :label="1">Deposit</el-radio-button>
              <el-radio-button :label="0">Deduction</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="COINS">
            <el-input-number
              v-model="form.coins"
              :min="0"
              :max="form.type === 1 ? 2000 : form.oCoins"
              :step="100"
              style="width: 100%;"
            ></el-input-number>
          </el-form-item>
          <el-form-item>
            <div class="text-right">
              <el-button
                type="success"
                :disabled="!(company && form.coins > 0)"
                @click="giveCoins"
              >
                Save
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog
        title="Edit Pro Accounts"
        v-if="company"
        :visible.sync="showCompanyPro"
        width="50%"
      >
        <el-form label-width="100px">
          <el-form-item label="Company">
            <b>
              {{ company.name }}
            </b>
          </el-form-item>
          <el-form-item label="Pro Accounts">
            <b class="text-success">
              {{ form.oPro }}
            </b>
          </el-form-item>
          <el-form-item label="Type">
            <el-radio-group v-model="form.type">
              <el-radio-button :label="1">Deposit</el-radio-button>
              <el-radio-button :label="0">Deduction</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="">
            <el-input-number
              v-model="form.Pro"
              :min="0"
              :max="form.type === 1 ? 2000 : form.oPro"
              :step="10"
              style="width: 100%;"
            ></el-input-number>
          </el-form-item>
          <el-form-item>
            <div class="text-right">
              <el-button
                type="success"
                :disabled="!(company && form.Pro > 0)"
                @click="addProAccounts"
              >
                Save
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div v-show="activeSection === 'Users'">
      <Users :companyId="companyId"></Users>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/apis/company";
import UserTransactions from "@/components/balance/UserTransactions.vue";
import Balance from "@/components/balance/Balance.vue";
import Users from "@/views/AdminCompany/Users.vue";
import Pro from "@/components/balance/Pro.vue";
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `Company  ${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, Users, UserTransactions, Balance, Pro },

  mixins: [],

  props: [],
  data() {
    return {
      activeSection: "Info",
      companyInfo: null,
      company: null,
      showCompany: false,
      showCompanyPro: false,
      form: {
        id: this.$route.params.id,
        oCoins: 0,
        coins: 0,
        oPro: 0,
        Pro: 0,
        type: 1
      }
    };
  },
  computed: {
    myTitle() {
      return this.companyInfo ? this.companyInfo.name : "";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    companyId() {
      return this.$route.params.id;
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.active) {
      this.activeSection = this.$route.query.active;
    }
    this.adminGetCompanyInfo();
  },

  methods: {
    editBalance() {
      this.company = null;
      this.company = this.companyInfo;
      this.showCompany = true;
      this.form.id = this.companyInfo.id;
      this.form.oCoins = this.companyInfo.coin;
    },
    async giveCoins() {
      if (this.form.type === 1) {
        await Company.giveCoins(this.form.id, {
          coins: this.form.coins
        });
        this.$message({
          message: "Success!",
          type: "success"
        });
      } else {
        await Company.deductCoins(this.form.id, {
          coins: this.form.coins
        });
      }
      this.adminGetCompanyInfo();
      this.showCompany = false;
      this.form = {
        id: this.$route.params.id,
        oCoins: 0,
        coins: 0,
        oPro: 0,
        Pro: 0,
        type: 1
      };
    },
    editPro() {
      this.showCompanyPro = true;
      this.company = null;
      this.company = this.companyInfo;
      this.form.id = this.companyInfo.id;
      this.form.oPro = this.companyInfo.pro_quantity;
    },
    async addProAccounts() {
      if (this.form.type === 1) {
        await Company.adminGivePro(this.form.id, {
          quantity: this.form.Pro
        });
      } else {
        await Company.adminDeductPro(this.form.id, {
          quantity: this.form.Pro
        });
      }
      this.adminGetCompanyInfo();
      this.showCompanyPro = false;
      this.form = {
        id: this.$route.params.id,
        oCoins: 0,
        coins: 0,
        oPro: 0,
        Pro: 0,
        type: 1
      };
    },
    async adminGetCompanyInfo() {
      const res = await Company.adminGetCompanyInfo(this.companyId);
      this.companyInfo = res.company;
    }
  }
};
</script>

<style scoped></style>
